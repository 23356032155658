.find-a-home {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 1292px;
  position: relative;
  width: 1440px;
}

