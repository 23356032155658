:root { 
  --amazon: #4a7c59;
  --black: #000000;
  --black-2: #00000066;
  --blue-zodiac: #092649;
  --cornflower: #a3cef1;
  --hippie-blue: #6096ba;
  --midnight-blue: #274c77;
  --summer-green: #8fc0a9;
  --white: #ffffff;
 
  --font-size-60px: 60px;
  --font-size-l: 30px;
  --font-size-m: 28px;
  --font-size-s: 27px;
  --font-size-xs: 25px;
  --font-size-xxs: 23px;
  --font-size-xxxs: 22px;
  --font-size-xxxxs: 21px;
 
  --font-family-libre_baskerville: "Libre Baskerville", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
}
