.home-page-v3 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 1905px;
  overflow: hidden;
  width: 1440px;
}

