.voucher-tool-v3-tutorial-back-button {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 1024px;
  overflow: hidden;
  width: 1440px;
}

