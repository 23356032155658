.voucher-tool-v3-required-paperwork-dob {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 1024px;
  overflow: hidden;
  width: 1440px;
}

